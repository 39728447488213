import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

import SwipeableViews from 'react-swipeable-views';

import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import PersonPinCircleTwoToneIcon from '@mui/icons-material/PersonPinCircleTwoTone';

import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';

import CommentsTab from '../components/CommentsTab';

import CarouselTab from '../components/CarouselTab';
import WithoutResults from '../components/WithoutResults'

import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { green } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';

import {
    Box,
    Container,
    Typography
} from '@mui/material'

import ServicesTab from './ServicesTab'
import MapTab from '../components/MapTab'

const AntTabs = styled(Tabs)({
    backgroundColor: '#eee',
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function CardTabs(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(props.index_tab);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const useStyles = makeStyles((theme) => ({
        tabStyle: {
            '&.MuiTabs-indicatorSpan': {
                color: '#1DB2F5',
                fontWeight: 'bold',
            },
        },
        scrollButtons: {
            width: 'initial',

            '&:first-child': {
                backgroundColor:
                    theme.palette.type === 'dark'
                        ? theme.palette.grey[800]
                        : theme.palette.grey[50],
                paddingRight: theme.spacing(2),
                height: '100%',
                position: 'absolute',
                left: 0,
                opacity: 1,
                zIndex: 1,
            },

            '&.MuiTabs-scrollButtonsDesktop': {
                display: 'flex',

                '&.Mui-disabled:first-child': {
                    visibility: 'hidden',
                },
            },
        },
    }));

    const classes = useStyles();

    return (
        <Container sx={{
            flexGrow: 1,
            maxWidth: {
                xs: `calc(100vw - ${theme.spacing(5)})`,
                sm: 800,
                md: 1000,
                lg: 1200,
                xl: 1200,
            },
            width: '100%',
        }}
        >
            <AntTabs
                variant="scrollable"
                scrollButtons={true}
                allowScrollButtonsMobile
                value={value} onChange={handleChange} aria-label="icon label tabs example">
                <AntTab icon={<RoomServiceTwoToneIcon sx={{ color: '#1DB2F5', }} />} label="SERVICIOS" {...a11yProps(0)} />
                <AntTab icon={<PhotoCameraTwoToneIcon sx={{ color: '#1DB2F5', }} />} label="FOTOS" {...a11yProps(1)} />
                <AntTab icon={<MessageTwoToneIcon sx={{ color: '#1DB2F5', }} />} label="OPINIONES" {...a11yProps(2)} />
                <AntTab icon={<PersonPinCircleTwoToneIcon sx={{ color: '#1DB2F5', }} />} label="UBICACION" {...a11yProps(3)} />
                <AntTab icon={<LocalActivityTwoToneIcon sx={{ color: '#1DB2F5', }} />} label="PROMOCIONES" {...a11yProps(4)} />
            </AntTabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <ServicesTab servicios={props.servicios} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <CarouselTab imagenes={props.imagenes} codigo_prestador={props.codigo_prestador} tipo='fotos' />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <CommentsTab
                        comentarios={props.comentarios}
                    />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    {
                        props.lat !== 0
                            ?
                            <MapTab
                                nombre={props.nombre}
                                imagen_mapa={props.imagen_mapa}
                                lat={props.lat}
                                lon={props.lon}
                            /> :
                            <WithoutResults tipo_resultado={'mapa'} descripcion={'Ubicación en mapa no disponible'} />
                    }
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    {
                        props.promociones.length !== 0
                            ?
                            <CarouselTab
                                imagenes={props.promociones}
                                codigo_prestador={props.codigo_prestador}
                                tipo='promociones' />
                            :
                            <WithoutResults tipo_resultado={'promocion'} descripcion={'Sin promociones para mostrar'} />
                    }
                </TabPanel>
            </SwipeableViews>
            <Zoom
                key="primary"
                in={value === 2}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${value === 2 ? transitionDuration.exit : 0}ms`,
                }}
                unmountOnExit
            >
                <Fab
                    onClick={props.clickOpenInsertComment}
                    variant="circular"
                    size="large"
                    sx={{
                        bottom: 16,
                        right: 16,
                        float: 'right',
                        background: '#007FFF',
                    }}
                    aria-label="Expand" >
                    {<AddIcon />}
                </Fab>
            </Zoom>
        </Container>
    );
}
