import * as React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormProviderDataBasic from './FormProviderDataBasic';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ws_image from '../../assets/images/whatsapp.png'
import ig_image from '../../assets/images/facebook.png'
import fb_image from '../../assets/images/ic-instagram.png'
import page_image from '../../assets/images/page.png'
import email_image from '../../assets/images/email.png'
import { styled } from '@mui/material/styles';
import { IconButton, Container, Alert as MuiAlert, Snackbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SocialMediaProvider, TypeProvider, ServicesProvider } from './RegisterProvider';
import DataBasicProvider from './RegisterProvider';
import ProviderService from '../../servicio/ProviderService';
import { ImagesProvider } from './RegisterProvider';
import UploadImagesService from '../../servicio/UploadImagesService';
import { useEffect } from 'react';

import Recommended from '../components/Recommended';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress'

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  objectFit: 'cover',
  display: 'flex',
});

const steps = [
  {
    id: 1,
    label: 'Datos basicos',
    description: `Datos basicos`,
  },
  {
    id: 2,
    label: 'Tipo de prestador de servicio',
    description:
      'Tipo de prestador de servicio',
  },
  {
    id: 3,
    label: 'Comunicaciones',
    description: `Comunicaciones`,
  },
  {
    id: 4,
    label: 'Servicios',
    description: `Servicios`,
  },
  {
    id: 5,
    label: 'Etiquetas',
    description: `Etiquetas`,
  },
  {
    id: 6,
    label: 'Fotografías',
    description: `Fotografías`,
  },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [prestador, setPrestador] = React.useState([]);
  const [nombre, setNombre] = React.useState('');
  const [direccion, setDireccion] = React.useState('');
  const [ciudad, setCiudad] = React.useState('');
  const [ws, setWs] = React.useState('');
  const [ig, setIg] = React.useState('');
  const [fb, setFb] = React.useState('');
  const [www, setWww] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [servicio1, setServicio1] = React.useState('');
  const [servicio2, setServicio2] = React.useState('');
  const [servicio3, setServicio3] = React.useState('');
  const [etiqueta, setEtiqueta] = React.useState('');

  const [imagenes, setImagenes] = React.useState([]);
  const [vista_previa, setVistaPrevia] = React.useState([]);
  const [nombres, setNombres] = React.useState([]);

  const [open, setOpen] = React.useState(false)
  const [vertical, setVertical] = React.useState('bottom');
  const [horizontal, setHorizontal] = React.useState('center');
  const [colorMessage, setColorMessage] = React.useState('success');
  const [message, setMessage] = React.useState('')

  const [tipoprestador, setTipoPrestador] = React.useState({
    todos: true,
    hotel: false,
    restaurant: false,
    paseos: false,
    operador: false,
    buses: false,
    toldos: false,
    seguros: false
  });

  const [porcentaje, setPorcentaje] = React.useState(0);

  const changeHandler = (event) => {

    switch (event.target.name) {
      case 'nombre':
        setNombre(event.target.value);
        break;
      case 'direccion':
        setDireccion(event.target.value);
        break;
      case 'ciudad':
        setCiudad(event.target.value);
        break;
      case 'ws':
        setWs(event.target.value);
        break;
      case 'ig':
        setIg(event.target.value);
        break;
      case 'fb':
        setFb(event.target.value);
        break;
      case 'www':
        setWww(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'servicio1':
        setServicio1(event.target.value);
        break;
      case 'servicio2':
        setServicio2(event.target.value);
        break;
      case 'servicio3':
        setServicio3(event.target.value);
        break;
      case 'etiqueta':
        setEtiqueta(event.target.value);
        break;
      case 'imagenes':

        if (event.target.files) {

          setImagenes(imagenes => [...imagenes, event.target.files])
          /* Get files in array form */
          //console.log(event.target.files);
          const files = Array.from(event.target.files);

          /* Map each file to a promise that resolves to an array of image URI's */
          const archivos = [];

          Promise.all(files.map(file => {
            archivos.push(file.name);
            return (new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener('load', (ev) => {
                resolve(ev.target.result);
              });
              reader.addEventListener('error', reject);
              reader.readAsDataURL(file);
            }));
          }))
            .then(images => {

              /* Once all promises are resolved, update state with image URI array */
              var joined_1 = vista_previa.concat(images);
              var joined_2 = nombres.concat(archivos);

              setVistaPrevia(joined_1)
              setNombres(joined_2)

            }, error => {
              console.error(error);
            });
        }
        break;
    }

  }

  const changeHandlerChekbox = (event) => {
    setTipoPrestador({
      ...tipoprestador,
      [event.target.name]: event.target.checked,
    });
  }

  const uploadFiles = (id) => {

    let arreglo_imagenes = [];
    for (let i = 0; i < imagenes.length; i++) {
      for (let j = 0; j < imagenes[i].length; j++) {
        arreglo_imagenes.push(imagenes[i][j])
      }
    }

    //console.log(files);
    if (arreglo_imagenes.length === 0 || arreglo_imagenes === undefined) {
      mostrarNotificacion('error', 'Debe seleccionar al menos una imagen.')
      return;
    }

    const config = {
      headers: { "content-type": `${arreglo_imagenes[0].type}` },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setPorcentaje(Math.floor((loaded * 100) / total));
        console.log(`${loaded}kb de ${total} | ${percent}%`);
      }
    };

    const formData = new FormData();

    if (arreglo_imagenes.length === 1) {

      formData.append('image', arreglo_imagenes[0])

      axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/simple/${id}/General`, formData, config)
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            mostrarNotificacion('success', 'Información registrada con exito')
            setImagenes([]);
            setNombres([]);
            setVistaPrevia([]);
            limpiar();
          }

        }, error => {
          mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
          console.error(error);
        });

    } else {

      for (let i = 0; i < arreglo_imagenes.length; i++) {
        formData.append('images', arreglo_imagenes[i])
      }

      axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/multiple/${id}/General`, formData, config)
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            mostrarNotificacion('success', 'Información registrada con exito')
            setImagenes([]);
            setNombres([]);
            setVistaPrevia([]);
            limpiar();
          }

        }, error => {
          mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
          console.error(error);
        });

    }
  }

  const removeImage = (imagen, nombre) => {
    console.log('img', imagenes);

    let arreglo_imagenes = [];
    for (let i = 0; i < imagenes.length; i++) {
      for (let j = 0; j < imagenes[i].length; j++) {
        console.log('name', imagenes[i][j].name);
        if (imagenes[i][j].name !== nombre) {
          arreglo_imagenes.push(imagenes[i][j]);
        }
      }
    }

    console.log(arreglo_imagenes);
    setImagenes([]);
    setImagenes(imagenes => [...imagenes, arreglo_imagenes]);

    //setImagenes(arreglo_imagenes);

    setNombres(nombres.filter(item => item !== nombre))
    setVistaPrevia(vista_previa.filter(item => item !== imagen))
  }

  const limpiar = (event) => {
    setNombre('');

    setDireccion('');

    setCiudad('');

    setWs('');

    setIg('');

    setFb('');

    setWww('');

    setEmail('');

    setServicio1('');

    setServicio2('');

    setServicio3('');

    setEtiqueta('');

    setTipoPrestador({
      ...tipoprestador,
      todos: true,
      hotel: false,
      restaurant: false,
      paseos: false,
      operador: false,
      buses: false,
      toldos: false,
      seguros: false
    });

  }

  const handleNext = (event) => {

    if (!validarCampos(activeStep)) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setPrestador([])

    switch (activeStep) {
      case 5:
        prestador.push({
          nombre: nombre,
          direccion: direccion,
          codigo_ciudad: ciudad,
          codigo_prestador: 0,
          clave: "",
          activo: 0,
          codigo_pais: "VEN",
          premium: 0,
          tipoprestador: tipoprestador,
          ws: ws,
          fb: fb,
          ig: ig,
          www: www,
          email, email,
          servicio1: servicio1,
          servicio2: servicio2,
          servicio3: servicio3,
          etiqueta: etiqueta
        })

        ProviderService
          .preregistro(prestador)
          .then((response) => {

            if (imagenes.length !== 0) {
              uploadFiles(response.data);
            } else {
              setPorcentaje(100);
              mostrarNotificacion('success', 'Información registrada con exito')
              limpiar()
            }

          },
            error => {
              mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
              console.log(error.toString())
            })


        //console.log(prestador)
        break
      default:
        break
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);

  };

  useEffect(() => {


  }, [nombres, imagenes])

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const mostrarNotificacion = (tipo, mensaje) => {
    setOpen(true);
    setColorMessage(tipo)
    setMessage(mensaje)
  }

  const validarCampos = (step) => {
    switch (step) {
      case 0:

        if (nombre.length === 0) {
          mostrarNotificacion('error', 'Debe indicar Nombre')
          return false
        }

        if (direccion.length === 0) {
          mostrarNotificacion('error', 'Debe indicar Dirección')
          return false
        }

        if (ciudad.length === 0) {
          mostrarNotificacion('error', 'Debe indicar Ciudad')
          return false
        }

        return true;

      case 1:

        let valor = false;
        if (tipoprestador.hotel) {
          valor = true;
        }
        if (tipoprestador.restaurant) {
          valor = true;
        }
        if (tipoprestador.paseos) {
          valor = true;
        }
        if (tipoprestador.operador) {
          valor = true;
        }
        if (tipoprestador.buses) {
          valor = true;
        }
        if (tipoprestador.toldos) {
          valor = true;
        }
        if (tipoprestador.seguros) {
          valor = true;
        }

        if (!valor) {
          mostrarNotificacion('error', 'Debe indicar al menos un tipo de servicio')
          return false
        }

        return true;

      case 2:

        if (ws.length === 0) {
          mostrarNotificacion('error', 'Debe indicar un número de contacto')
          return false;
        }

        return true;

      case 3:

        if (servicio1.length === 0 && servicio3.length === 0 && servicio3.length === 0) {
          mostrarNotificacion('error', 'Debe indicar al menos un servicio a prestar')
          return false
        }

        return true;

      case 4:

        if (etiqueta.length === 0) {
          mostrarNotificacion('error', 'Debe indicar etiqueta relacionada con servicios prestados')
          return false
        }

        return true;

      case 5:

        return true;

    }
  }

  return (
    <Container component="section" id="contenedor"
      sx={{
        marginTop: 12,
        background: '#eee',
      }}
    >
      <Recommended titulo={'Preregistro de prestadores turísticos'} />
      <Box sx={{ maxWidth: '100%' }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 5 ? (
                    <Typography variant="caption">Último paso</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {step.id === 1 ?
                  <DataBasicProvider
                    nombre={nombre}
                    direccion={direccion}
                    ciudad={ciudad}
                    changeHandler={changeHandler}
                  />
                  :
                  step.id === 2 ?
                    <TypeProvider
                      tipoprestador={tipoprestador}
                      changeHandlerChekbox={changeHandlerChekbox}
                    />
                    :
                    step.id === 3 ?
                      <SocialMediaProvider
                        ws={ws}
                        fb={fb}
                        ig={ig}
                        www={www}
                        email={email}
                        changeHandler={changeHandler}
                      />
                      :
                      step.id === 4 ?
                        <ServicesProvider
                          servicio1={servicio1}
                          servicio2={servicio2}
                          servicio3={servicio3}
                          changeHandler={changeHandler} />
                        :
                        step.id === 5 ?
                          <TextField
                            required
                            autoFocus
                            id="etiqueta"
                            name="etiqueta"
                            type="text"
                            label="Etiquetas"
                            fullWidth
                            inputProps={{ maxLength: 300 }}
                            value={etiqueta}
                            onChange={changeHandler}
                          />
                          :
                          step.id === 6 ?
                            <ImagesProvider
                              imagenes={imagenes}
                              vista_previa={vista_previa}
                              nombres={nombres}
                              porcentaje={porcentaje}
                              changeHandler={changeHandler}
                              uploadFiles={uploadFiles}
                              removeImage={removeImage}
                            />
                            :
                            null
                }

                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button

                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Atrás
                    </Button>
                  </div>
                </Box>
              </StepContent>

            </Step>

          ))}
        </Stepper>
        {
          activeStep === steps.length && porcentaje !== 100
            ?
            <Paper square elevation={0} sx={{ p: 3 }}>
              <h1>No cerrar hasta tanto no finalice la carga</h1>
              <Typography>Progreso total:</Typography>
              <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={porcentaje} />
              </Box>
            </Paper>
            : null
        }
        {activeStep === steps.length && porcentaje === 100 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>Todos los pasos completados</Typography>
            <h1>Registro finalizado. {nombre}. Pronto se comunicaran con usted para confirmar el registro!</h1>
            {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Agregar otro
            </Button> */}
          </Paper>
        )}
      </Box>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar} variant="filled" anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleCloseSnackBar} severity={colorMessage} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}