import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { useState, useEffect } from 'react';

import { green, pink } from '@mui/material/colors';

import Data from '../../servicio/Data';

import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

import {
    Avatar,
    Badge,
    Button,
    Collapse,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Card,
    ButtonBase,
    Stack,
    Rating,
    Typography,
    IconButton,
    Alert,
    AlertTitle
} from '@mui/material';

import Fab from '@mui/material/Fab';

import { makeStyles } from '@mui/styles';

import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';

import SwipeableTextMobileStepper from './SwipeableTextMobileStepper';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Turismo123 from '../../assets/images/turismo123.png';
import FloatingWhatsApp from 'react-floating-whatsapp'

import CardTabs from './CardTabs';

import FunctionsCommon from '../functions/FunctionsCommon';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination'

import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import useMediaQuery from '@mui/material/useMediaQuery';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import AssistantDirectionTwoToneIcon from '@mui/icons-material/AssistantDirectionTwoTone';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';

import icInstgram from '../../assets/images/ic-instagram.png'
import icWWW from '../../assets/images/www.png'

import ActionButton from '../components/ActionButton'

import AddCommentTwoToneIcon from '@mui/icons-material/AddCommentTwoTone';

import DeckTwoToneIcon from '@mui/icons-material/DeckTwoTone';
import CommuteTwoToneIcon from '@mui/icons-material/CommuteTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import DiningTwoToneIcon from '@mui/icons-material/DiningTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import VpnLockTwoToneIcon from '@mui/icons-material/VpnLockTwoTone';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon sx={{ color: 'azure' }} /> : <FirstPageIcon sx={{ color: 'azure' }} />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight sx={{ color: 'azure' }} /> : <KeyboardArrowLeft sx={{ color: 'azure' }} />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft sx={{ color: 'azure' }} /> : <KeyboardArrowRight sx={{ color: 'azure' }} />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon sx={{ color: 'azure' }} /> : <LastPageIcon sx={{ color: 'azure' }} />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function CardReponsive(props) {
    const [expand, setExpand] = React.useState(false);
    const [idprestador, setIDPrestador] = React.useState(-1);

    const [open, setOpen] = React.useState({});
    const [index_tab, setIndex_Tab] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [total, setTotal] = React.useState(props.prestadores.length);

    const [servicios, setServicios] = React.useState([])

    //console.log(props.prestadores);

    const handleExpandClick = (id) => {
        setOpen((prevState => ({ ...prevState, [id]: !prevState[id] })));

        setExpand(!expand);
        setIDPrestador(id);
        setIndex_Tab(0);
    }

    const handleExpandTabClick = (id, tab) => {
        setOpen((prevState => ({ ...prevState, [id]: !prevState[id] })));

        setExpand(!expand);
        setIDPrestador(id);

        setIndex_Tab(tab);
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.prestadores.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const obteniendoPromedio = (arreglo) => {
        let sumatoria = 0;
        if (arreglo.length !== 0) {
            for (let i in arreglo) {
                sumatoria = parseFloat(sumatoria) + parseFloat(arreglo[i].puntuacion);
            }
        } else {
            return 0;
        }

        let promedio = sumatoria / arreglo.length;

        return promedio.toFixed(2);
    }

    const redirigir = (tipo, cuenta) => {
        
        switch (tipo) {
            case 'ig':
                window.open('https://www.instagram.com/' + cuenta + '/');
                break;
            case 'www':
                window.open(cuenta);
                break;
        }
    }

    const obtenerIconRedes = (tipo) => {
        switch (tipo) {
            case 'ig':
                return icInstgram;
            case 'www':
                return icWWW;
        }
    }

    const useStyles = makeStyles(() => ({
        actionArea: {
            borderRadius: 10,
            boxShadow: `0 1px 2px 0 `,
            transition: '0.1s',
            '&.css-bhp9pd-MuiPaper-root-MuiCard-root': {
                borderRadius: 10,
                overflow: 'unset',
            },
            '&.css-s18byi': {
                overflow: 'unset',
            }
        },
        alertInfo: {
            backgroundColor: '#EBEFFF',
            height: '100%',
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#D1E9F6',
                cursor: 'pointer',
                '& .css-132jxnu-MuiPaper-root': {
                    backgroundColor: '#D1E9F6'
                }
            },

        },
        alertSuccess: {
            backgroundColor: '#EFFCF4',
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#D1E9F6',
                cursor: 'pointer',
                '& .css-132jxnu-MuiPaper-root': {
                    backgroundColor: '#D1E9F6'
                }
            },
        },
        paperStyle: {
            '&.MuiPaper-root': {
                boxShadow: 'none',
                backgroundColor: 'transparent',
            },
        },
        stackDetails: {
            '&.css-29nqov-MuiStack-root>:not(style)+:not(style)': {

            }
        },
        selectDropdown: { color: "#000", backgroundColor: "#fff" },
        menuItem: {
            "&:hover": {
                backgroundColor: "#3b3f58",
                color: '#fff'
            }
        }
    }));

    const classes = useStyles();

    const matches = useMediaQuery('(max-width:600px)');

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)');

    const obtenerPaddingRight = () => {
        if (m320) {
            return '16px'
        } else if (m360) {
            return '16px'
        } else if (m375) {
            return '0px'
        } else if (m411) {
            return '16px'
        } else if (m425) {
            return '0px'
        } else if (m1600) {
            return '0px'
        } else if (m1800) {
            return '0px'
        } else {
            return '16px'
        }
    }

    // xs: 285,
    //                                                                 sm: 505,
    //                                                                 md: 270,
    //                                                                 xl: 285,
    //                                                                 lg: 285

    const obtenerWithMobileStepper = () => {
        if (m320) {
            return 285
        } else if (m360) {
            return '326px'
        } else if (m375) {
            return 340
        } else if (m411) {
            return '375px'
        } else if (m414) {
            return '377px'
        } else if (m425) {
            return 390
        } else if (m768) {
            return 505
        } else if (m1024) {
            return 285
        } else if (m1440) {
            return 285
        }
    }

    const obtenerMaxWith = () => {
        if (m360) {
            return '326px'
        } else {
            return '100%'
        }
    }

    const visibility = () => {
        if (m320) {
            return 'hidden'
        } else if (m360) {
            return 'hidden'
        } else if (m375) {
            return 'hidden'
        } else if (m411) {
            return 'hidden'
        } else if (m425) {
            return 'hidden'
        } else if (m1600) {
            return 'visible'
        } else if (m1800) {
            return 'visible'
        } else {
            return 'visible'
        }
    }

    const height = () => {
        if (m320) {
            return 0
        } else if (m360) {
            return 0
        } else if (m375) {
            return 0
        } else if (m411) {
            return 0
        } else if (m425) {
            return 0
        } else if (m1600) {
            return '100%'
        } else if (m1800) {
            return '100%'
        } else {
            return '100%'
        }
    }


    const obtenerIconServicio = (codigo) => {
        switch (codigo) {
            case 0:
                return <BallotTwoToneIcon />
            case 1:
                return <HotelTwoToneIcon />
            case 2:
                return <DiningTwoToneIcon />
            case 3:
                return <HikingTwoToneIcon />
            case 4:
                return <LuggageTwoToneIcon />
            case 5:
                return <CommuteTwoToneIcon />
            case 6:
                return <DeckTwoToneIcon />
            case 7:
                return <VpnLockTwoToneIcon />
            case 8:
                return <StarTwoToneIcon />
        }
    }

    const obtenerNombreServicio = (codigo) => {
        for (let i = 0; i < servicios.length; i++) {
            if (codigo === servicios[i].codigo_servicio) {
                return servicios[i].nombre
            }
        }
    }

    const obtenerColor = (codigo) => {
        // buses: azul
        // paseos: verde oscuro
        // rest: amarillo
        // op tur: morado
        // hoteles: marron
        // seguros: rojo
        // toldos: rosado
        switch (codigo) {
            case 1:
                //MARRON
                return "#D4821A"
            case 2:
                //NARANJA
                return "#FA8150"
            case 3:
                //VERDE
                return "#008A3F"
            case 4:
                //MORADO
                return "#A7164C"
            case 5:
                //AZUL OSCURO
                return "#006BD9"
            case 6:
                //ROSADO
                return "#FF67B1"
            case 7:
                //ROJO
                return "#F44336"
            case 8:
                //AMARILLO DESTACADOS
                return "#FFB719"
        }
    }

    React.useEffect(() => {
        Data
            .servicios()
            .then(
                (response) => {
                    setServicios(response.data);
                },
                error => {
                }
            );
    }, [])

    return (
        <Container component="section" id="contenedor"
            sx={{
                background: 'transparent',
                marginLeft: {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    xl: 0,
                    lg: 0,
                },
                paddingLeft: '0px !important',
                paddingRight: obtenerPaddingRight()
            }}
        >
            <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <TableContainer component={Paper}
                    sx={{
                        background: 'transparent',
                        boxShadow: 'none',
                        overflow: 'unset',
                        paddingLeft: '6px',
                    }}
                >
                    <Table aria-label="simple table" size="small" loading>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? props.prestadores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : props.prestadores
                            ).map((prestador) => (
                                <TableRow
                                    key={prestador.codigo_prestador}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell id="tabla_" component="th" scope="row"
                                        sx={{
                                            padding: {
                                                xs: '6px 0px 0px 0px',
                                                sm: '6px 0px 0px 45px',
                                                md: '6px 0px 0px 0px',
                                                xl: '6px 0px 0px 16px',
                                                lg: '6px 0px 0px 16px',
                                            },
                                            paddingTop: '0px !important',
                                            paddingBottom: '5px !important',
                                            borderColor: 'transparent'
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="right"
                                            alignItems="right"
                                            spacing={1}
                                            sx={{ marginBottom: '-20px' }}
                                        >
                                            {prestador.tipo_servicios.map((item) => (
                                                item.codigo_servicio !== 0 ?
                                                    <BootstrapTooltip title={obtenerNombreServicio(item.codigo_servicio)} placement="top">
                                                        <Avatar
                                                            sx={{
                                                                zIndex: 1,
                                                                bgcolor: obtenerColor(item.codigo_servicio),
                                                                '&:hover': {
                                                                    transform: 'scale(1.1)',
                                                                    boxShadow: `0 1px 5px 0`,
                                                                },
                                                            }}
                                                        >
                                                            {obtenerIconServicio(item.codigo_servicio)}
                                                        </Avatar>
                                                    </BootstrapTooltip>
                                                    :
                                                    null
                                            ))}
                                        </Stack>
                                        <Card key={prestador.codigo_prestador} className={classes.actionArea}>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Grid container spacing={0.1}>
                                                    <Stack
                                                        direction={{ xs: 'column', sm: 'column', md: 'row', xl: 'row', lg: 'row' }}
                                                        sx={{ maxWidth: obtenerMaxWith() }}
                                                    >
                                                        <Grid item>
                                                            <ButtonBase sx={{
                                                                height: 200,
                                                                width: { xs: '100%', md: 200, sm: 505 },
                                                                margin: 0,
                                                                display: 'flex',
                                                                objectFit: { xs: 'cover' },
                                                                maxHeight: { xs: 100, md: 200, sm: 200 },
                                                                maxWidth: { xs: '100%', md: 200, sm: 505 },
                                                                borderRadius: '10px 0px 0px 0px'
                                                            }}>
                                                                <Img
                                                                    alt="complex"
                                                                    src={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagen)}`}
                                                                    sx={{
                                                                        borderRadius: {
                                                                            xs: '10px 10px 0px 0px',
                                                                            sm: '10px 0px 0px 0px',
                                                                            md: '10px 0px 0px 0px',
                                                                            lg: '10px 0px 0px 0px',
                                                                            xl: '10px 0px 0px 0px',
                                                                        },
                                                                        objectFit: 'fill',
                                                                    }}
                                                                />
                                                            </ButtonBase>
                                                        </Grid>
                                                        <Grid item sx={{
                                                            width: {
                                                                xs: '100%',
                                                                sm: 505,
                                                                md: 280,
                                                                xl: 370,
                                                                lg: 370,
                                                            },
                                                            maxWidth: {
                                                                xs: '100%',
                                                                sm: '100%',
                                                                md: '100%',
                                                                xl: 370,
                                                                lg: 400,
                                                            }
                                                        }}
                                                        >
                                                            <Grid item>
                                                                <Item>
                                                                    <Alert className={classes.alertInfo}
                                                                        iconMapping={{
                                                                            info: <Avatar
                                                                                sx={{
                                                                                    borderRadius: 2,
                                                                                    background: 'linear-gradient(to left bottom, rgb(0, 77, 205) 20%, rgba(0, 77, 205, 0.584))',
                                                                                }}
                                                                                variant="square"
                                                                            >
                                                                                <PersonPinTwoToneIcon fontSize="large" />
                                                                            </Avatar>,
                                                                        }}
                                                                        variant="outlined" severity="info"
                                                                        sx={{
                                                                            width: '100%',
                                                                            padding: { sm: '0px 6px 0px 16px' },
                                                                            backgroundColor: '#EBEFFF',
                                                                        }}
                                                                    >
                                                                        <AlertTitle sx={{ fontSize: { sm: 14, } }}>{prestador.nombre}</AlertTitle>
                                                                        <Typography sx={{ fontSize: { sm: 14, } }} variant="body1" color="text.secondary">
                                                                            {prestador.codigo_ciudad}
                                                                        </Typography>

                                                                        <Stack
                                                                            direction={{ xs: 'column', sm: 'row' }}
                                                                            spacing={{ xs: 0, sm: 0, md: 7 }}
                                                                            className={classes.stackDetails}
                                                                        >
                                                                            <BootstrapTooltip title={obteniendoPromedio(prestador.comentario)} placement="right-end">
                                                                                <Item className={classes.paperStyle}>
                                                                                    <Rating sx={{
                                                                                        verticalAlign: 'bottom',
                                                                                    }}
                                                                                        name="size-large"
                                                                                        value={obteniendoPromedio(prestador.comentario)}
                                                                                        readOnly
                                                                                        precision={0.1}
                                                                                        size="small" />
                                                                                </Item>
                                                                            </BootstrapTooltip>
                                                                            <Item className={classes.paperStyle}>
                                                                                <Stack
                                                                                    direction="row"
                                                                                    sx={{
                                                                                        position: 'relative',
                                                                                        alignItems: 'center',
                                                                                        marginLeft: { md: '-20px', lg: '10px', xl: '70px', },
                                                                                        height: '25px',
                                                                                    }}
                                                                                >
                                                                                    <Fab
                                                                                        onClick={() => handleExpandClick(prestador.codigo_prestador)}
                                                                                        variant="extended"
                                                                                        size="small"
                                                                                        aria-label="view"
                                                                                        sx={{
                                                                                            color: 'azure',
                                                                                            textTransform: 'none',
                                                                                            top: { xs: '6px', sm: 0, md: 0, lg: 0 },
                                                                                            fontSize: { sm: 10 },
                                                                                            height: { sm: '35px' },
                                                                                            lineHeight: { sm: 1 },
                                                                                            left: { lg: '20px', xl: '-10px' },
                                                                                            background: '#007FFF',
                                                                                            '&:hover': {
                                                                                                color: 'black',
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {open[prestador.codigo_prestador] ? <RemoveCircleTwoToneIcon sx={{ mr: 1 }} /> : <AddCircleTwoToneIcon sx={{ mr: 1 }} />}
                                                                                        Ver detalles
                                                                                    </Fab>
                                                                                    {/* <Button onClick={() => handleExpandClick(prestador.codigo_prestador)}
                                                                                        sx={{
                                                                                            textTransform: 'none',
                                                                                            top: { xs: '6px', sm: 0, md: 0, lg: 0 },
                                                                                            fontSize: { sm: 10 },
                                                                                            height: { sm: '30px' },
                                                                                            lineHeight: { sm: 1.4 },
                                                                                            left: { lg: '20px', xl: '-10px' },
                                                                                            background: '#007FFF'
                                                                                        }}
                                                                                        variant="outlined" endIcon={open[prestador.codigo_prestador] ? <RemoveCircleTwoToneIcon /> : <AddCircleTwoToneIcon />}>
                                                                                        Ver detalles
                                                                                    </Button> */}
                                                                                    {/* <Typography sx={{ fontSize: { sm: 14, fontWeight: 'bold', color: '#006DC3' } }} variant="body2" color="text.secondary">
                                                                        Ver detalles
                                                                    </Typography>
                                                                    <IconButton
                                                                        onClick={() => handleExpandClick(prestador.id)}
                                                                        sx={{
                                                                            padding: 0,
                                                                            color: '#006DC3'
                                                                        }}
                                                                    >
                                                                        {open[prestador.id] ? <RemoveCircleTwoToneIcon /> : <AddCircleTwoToneIcon />}
                                                                    </IconButton> */}
                                                                                </Stack>
                                                                            </Item>
                                                                        </Stack>

                                                                    </Alert>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item
                                                                sx={{
                                                                    width: '100%',
                                                                    visibility: visibility(),
                                                                    height: height()
                                                                }}
                                                            >
                                                                <Item>
                                                                    <Alert
                                                                        iconMapping={{
                                                                            success: <Avatar
                                                                                sx={{
                                                                                    borderRadius: 2,
                                                                                    background: 'linear-gradient(to left bottom, rgb(0, 77, 205) 20%, rgba(0, 77, 205, 0.584))',
                                                                                }}
                                                                                variant="square"
                                                                            >
                                                                                <AssistantDirectionTwoToneIcon fontSize="large" />
                                                                            </Avatar>

                                                                        }}
                                                                        className={classes.alertSuccess}
                                                                        sx={{
                                                                            width: '100%',
                                                                            minHeight: { sm: 108, md: 110, xl: 108, lg: 110 },
                                                                            paddingBottom: { sm: '0px' }
                                                                        }}
                                                                        variant="outlined" severity="success">
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: { sm: 12, },
                                                                            }} variant="body2" color="text.secondary">
                                                                            {prestador.direccion}
                                                                        </Typography>
                                                                    </Alert>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item
                                                            sx={{
                                                                width: obtenerWithMobileStepper(),
                                                                visibility: visibility(),
                                                                height: height()
                                                            }}
                                                        >
                                                            <SwipeableTextMobileStepper imagenes={prestador.imagen} codigo_prestador={prestador.codigo_prestador} />
                                                        </Grid>
                                                    </Stack>
                                                </Grid>
                                                <Grid container sx={{ paddingTop: 0 }}>
                                                    <Grid item xs={12} sx={{ paddingTop: 0 }}>
                                                        <Item>
                                                            <CardMedia>
                                                                <Collapse in={open[prestador.codigo_prestador]} timeout="auto" unmountOnExit>
                                                                    <CardContent sx={{ padding: '10px', paddingLeft: 0, paddingRight: 0, paddingBottom: '0px !important' }}>
                                                                        <CardTabs
                                                                            nombre={prestador.nombre}
                                                                            lat={prestador.lat === undefined || prestador.lat === null || prestador.lat === 0 ? 0 : prestador.lat}
                                                                            lon={prestador.lon === undefined || prestador.lon === null || prestador.lon === 0 ? 0 : prestador.lon}
                                                                            imagenes={prestador.imagen}
                                                                            comentarios={prestador.comentario}
                                                                            codigo_prestador={prestador.codigo_prestador}
                                                                            index_tab={index_tab}
                                                                            imagen_mapa={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagen)}`}
                                                                            servicios={prestador.item}
                                                                            promociones={prestador.promociones}
                                                                            openInsertComment={props.openInsertComment}
                                                                            clickOpenInsertComment={() => props.clickOpenInsertComment(prestador.codigo_prestador)}
                                                                            clickCloseInsertComment={props.clickCloseInsertComment}
                                                                        />
                                                                    </CardContent>
                                                                </Collapse>
                                                            </CardMedia>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <CardActions
                                                sx={{
                                                    display: 'grid',
                                                    width: '100%',
                                                    paddingTop: '8px',
                                                    paddingRight: '30px',
                                                    paddingLeft: '8px',
                                                    paddingBottom: '8px',
                                                }}>
                                                <Box
                                                    display="grid"
                                                    gridTemplateColumns="repeat(12, 1fr)"
                                                    gap={2}
                                                >
                                                    <Box gridColumn="span 7" sx={{ paddingBottom: 0 }}>
                                                        {prestador.comunicacion.map((contacto) => (
                                                            contacto.tipo === 'ws' ?
                                                                <IconButton aria-label="whatsapp">
                                                                    <FloatingWhatsApp
                                                                        styles={{
                                                                            position: 'initial',
                                                                            width: 35,
                                                                            height: 35,
                                                                            fontSize: 12,
                                                                            boxShadow: `0 3px 6px 0 `,
                                                                        }}
                                                                        phoneNumber={contacto.cuenta}
                                                                        avatar={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagen)}`}
                                                                        accountName={prestador.nombre}
                                                                        chatMessage={"Un placer saludarte en que te puedo ayudar?"}
                                                                        placeholder="Escribe un mensaje"
                                                                        statusMessage={`Disponible entre ${contacto.hora_inicio} ${contacto.ampm_inicio} y ${contacto.hora_fin} ${contacto.ampm_fin}`}
                                                                    />
                                                                </IconButton>
                                                                : <IconButton
                                                                    size="medium"
                                                                    sx={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                    }}
                                                                >
                                                                    <Img
                                                                        src={obtenerIconRedes(contacto.tipo)}
                                                                        onClick={() => redirigir(contacto.tipo, contacto.cuenta)}
                                                                        sx={{
                                                                            objectFit: 'fill',
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                        ))}
                                                        {/* <IconButton aria-label="faccebook">
                                <FacebookOutlinedIcon sx={{ fontSize: 35 }} style={{ color: '#334C84' }} />
                            </IconButton>
                            <IconButton aria-label="location">
                                <PersonPinCircleTwoToneIcon sx={{ fontSize: 35 }} style={{ color: '#001E3C' }} />
                            </IconButton> */}
                                                    </Box>
                                                    <Box gridColumn="span 5" sx={{ textAlign: 'right', alignSelf: 'center' }}>
                                                        <ActionButton
                                                            descripcion="Opiniones"
                                                            background='#007FFF'
                                                            background_hover="F5F5F5"
                                                            visible={'visible'}
                                                            fontsize="0.875rem"
                                                            iconbutton={<AddCommentTwoToneIcon sx={{ mr: 1 }} />}
                                                            onClick={() => handleExpandTabClick(prestador.codigo_prestador, 2)}
                                                        />
                                                        {prestador.promociones.length === 0
                                                            ? null
                                                            :
                                                            <ActionButton
                                                                descripcion="Promoción"
                                                                background="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);"
                                                                background_hover="linear-gradient(317deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);"
                                                                visible={prestador.promociones.length === 0 ? 'hidden' : 'visible'}
                                                                fontsize="13px"
                                                                iconbutton={<LocalActivityTwoToneIcon sx={{ mr: 1 }} />}
                                                                onClick={() => handleExpandTabClick(prestador.codigo_prestador, 4)}
                                                            />
                                                        }

                                                    </Box>
                                                </Box>
                                            </CardActions>
                                        </Card>

                                    </TableCell>

                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <TablePagination
                        sx={{ color: 'azure' }}
                        rowsPerPageOptions={matches ? [] : [1, 2, 3, 5, 10, 25, { label: 'Todos', value: -1 }]}
                        labelRowsPerPage={"Resultados por página"}
                        count={props.prestadores.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            MenuProps: { classes: { paper: classes.selectDropdown } }
                        }}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count !== -1 ? count : `Más de ${to}`}`}
                        classes={{ menuItem: classes.menuItem }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Stack>

            </Grid>
        </Container >
    );
}
