import * as React from 'react';
import {
    AccordionSummary,
    Avatar,
    Chip,
    Container,
    Divider,
    Drawer,
    IconButton,
    ListItemIcon,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Radio,
    RadioGroup,
} from '@mui/material';

import { Paper as PaperMui } from '@mui/material';

import { styled } from '@mui/material/styles';

import SkeletonCard from '../components/SkeletonCard';
import CardResponsive from '../components/CardResponsive'

import Data from '../../servicio/Data';

import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import MuiAccordion from '@mui/material/Accordion';
import ArrowDropDownCircleTwoToneIcon from '@mui/icons-material/ArrowDropDownCircleTwoTone';
import NotListedLocationTwoToneIcon from '@mui/icons-material/NotListedLocationTwoTone';
import ExploreTwoToneIcon from '@mui/icons-material/ExploreTwoTone';

import { makeStyles } from '@mui/styles';

import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';

import WithoutResults from './WithoutResults';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(PaperMui)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
    <MuiAccordion elevation={4} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    color: 'azure',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&:hover': {
        transition: '0.1s',
        transform: 'scale(1.0001)',
        boxShadow: `0 1px 3px 0`,
        backgroundColor: '#0095DE',
        cursor: 'pointer',
        '& .css-jikzxl-MuiTypography-root': {
            fontWeight: 'bold',
            color: 'azure',
        },
        '& .css-10mhs0l-MuiSvgIcon-root': {
            color: 'azure',
        },
        '& .css-scqpv1-MuiSvgIcon-root': {
            color: 'azure',
        },
        '& .css-p30gpn': {
            color: 'azure',
        },
        '& .css-1yjl5cc': {
            color: 'azure',
        }
    },
    '& .css-10tthpj-MuiButtonBase-root-MuiListItemButton-root': {
        color: 'white',
        '&:hover': {
            color: 'azure',
            '& .css-1579q7f-MuiSvgIcon-root': {
                color: 'azure',
            }
        },
    },
}));

const Paper = styled((props) => (
    <PaperMui elevation={4} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    color: 'azure',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&:hover': {
        transition: '0.1s',
        transform: 'scale(1.0001)',
        boxShadow: `0 1px 3px 0`,
        backgroundColor: '#0095DE',
        cursor: 'pointer',
        color: 'azure',
    },
}));

const FireNav = styled(List)({
    background: 'linear-gradient(to left bottom, rgb(0, 77, 205) 20%, rgba(0, 77, 205, 0.584))',
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '&:hover': {
        '& .css-le9ab0-MuiAvatar-root': {
            background: 'azure'
        },
        '& .css-tn8bk4': {
            background: 'azure'
        }
    }
});

export default function AdvancedSearch(props) {

    const [paises, setPaises] = React.useState([])
    const [estados, setEstados] = React.useState([])

    const [optionToggleCiudad, setOptionToggleCiudad] = React.useState('')
    const [optionTogglePais, setOptionTogglePais] = React.useState('')

    const [drawer, setDrawer] = React.useState({
        left: false,
    });

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)');

    const useStyles = makeStyles(() => ({
        radioStyle: {
            color: 'black',
            '&:hover': {
                color: 'azure',
            }
        },
        accordionStyle: {
            '&:hover': {
                '& .css-xnetll-MuiTypography-root': {
                    fontWeight: 'bold',
                },
                '& .css-6onzc7-MuiChip-root .MuiChip-avatar': {
                    fontWeight: 'bold',
                }
            }
        },
        toggleButton: {
            borderColor: 'transparent',
            '& .css-54vrx0-MuiButtonBase-root-MuiToggleButton-root': {
                paddingRight: '0px',
            },
            '&:hover': {
                '& .MuiChip-avatar': {
                    color: 'azure',
                    cursor: 'pointer',
                },
                '& .css-7v9d59-MuiTypography-root': {
                    fontWeight: 'bold',
                    cursor: 'pointer',
                }
            },
            "&.Mui-selected, &.Mui-selected:hover": {
                color: "black",
                backgroundColor: '#4751A6'
            }
        },
        chipLabel: {
            '& .css-6od3lo-MuiChip-label': {
                paddingRight: '5px !important',
            }
        },
        paper: {
            background: "linear-gradient(180deg,rgba(0,212,255,1) 0%,rgba(0,0,81,1) 70%,rgba(2,0,36,1) 100%)"
        }
    }));

    const classes = useStyles();
    React.useEffect(() => {
        Data
            .paises()
            .then((response) => {
                let datos = [];
                datos.push({
                    codigo_pais: response.data[0].codigo_pais,
                    nombre: response.data[0].nombre
                })
                setPaises(datos);
                Data
                    .estados('ven')
                    .then((response) => {
                        //console.log('estados', response.data);
                        setEstados(response.data);
                    })
            })
    }, [])

    const handleChangeTogglePais = (event, opcion) => {
        setOptionTogglePais(opcion);
        setOptionTogglePais((state) => {
            return state;
        });
        props.handleTogglePaises(opcion)
    };

    const handleChangeToggleCiudad = (event, opcion) => {
        setOptionToggleCiudad(opcion);
        setOptionToggleCiudad((state) => {
            return state;
        });
        props.handleToggleCiudades(opcion)
    };

    const obtenerTotalPorPais = () => {
        let total = 0;
        if (estados.length !== 0) {
            for (let i = 0; i < estados.length; i++) {
                total += estados[i].cantidad;
            }
        } else {
            return 0;
        }

        return total
    }

    const toggleDrawer = (open) => (event) => {
        console.log(open);
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawer(open)
    };

    const obtenerEstilo = () => {
        if (m320 || m360 || m375 || m425) {
            return {
                maxWidth: {
                    xs: '100% !important',
                    sm: '23.333333% !important',
                    md: '21.333333% !important',
                    xl: '21.333333% !important',
                    lg: '21.333333% !important',
                },
                paddingTop: '20px',
            }
        } else {
            return {
                maxWidth: {
                    xs: '100% !important',
                    sm: '23.333333% !important',
                    md: '21.333333% !important',
                    xl: '21.333333% !important',
                    lg: '21.333333% !important',
                },
                paddingTop: '20px',
                maxHeight: 900,
                overflowY: 'scroll',
                overflowX: 'hidden',
                minWidth: 250,
            }
        }
    }

    const obtenerFiltro = () => {
        return <Grid item xs={4}
            sx={obtenerEstilo()}
        >
            <FireNav id="aqui" component="nav" disablePadding
                sx={{
                    width: '100%',
                    marginRight: '60px',
                }}
            >
                <Divider />
                <ListItemButton component="a">
                    <ListItemIcon><Avatar><TravelExploreTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} /></Avatar></ListItemIcon>
                    <ListItemText
                        sx={{ my: 0 }}
                        primary="Pais"
                        primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                        }}
                    />
                </ListItemButton>
                <Divider />
                <Divider />
            </FireNav>
            {paises.map((pais) => {
                const labelIdPaises = `checkbox-list-secondary-label-${pais.nombre}`;
                return (
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        orientation="vertical"
                        value={optionTogglePais}
                        exclusive
                        onChange={handleChangeTogglePais}
                        sx={{
                            paddingBottom: 1,
                            paddingLeft: 1,
                        }}
                    >
                        <ToggleButton
                            fullWidth
                            key={pais.codigo_pais}
                            value={pais.nombre}
                            className={classes.toggleButton}
                            sx={{
                                justifyContent: 'left',
                                paddingLeft: '0px',
                                paddingRight: '0px'
                            }}
                        >
                            <Chip
                                sx={{ background: 'transparent', height: 'auto' }}
                                avatar={
                                    <ExploreTwoToneIcon
                                        fontSize="medium"
                                        sx={{
                                            paddingRight: 1,
                                            color: 'rgb(0, 77, 205)',
                                        }} />
                                }
                                label={
                                    <Chip
                                        sx={{ background: 'transparent', height: 'auto', }}
                                        avatar={<Avatar sx={{ color: 'red !important', }}>
                                            {
                                                obtenerTotalPorPais()
                                            }
                                        </Avatar>}
                                        className={classes.chipLabel}
                                        label={
                                            <Typography
                                                sx={{
                                                    flexShrink: 0,
                                                    alignSelf: 'center',
                                                    color: 'azure',
                                                    fontSize: '12px',
                                                    textTransform: 'none',
                                                }}>
                                                {pais.nombre}
                                            </Typography>}
                                    />
                                }
                            />
                        </ToggleButton>
                    </ToggleButtonGroup>
                );
            })}
            <FireNav component="nav" disablePadding>
                <Divider />
                <ListItemButton component="a">
                    <ListItemIcon><Avatar><MapTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} /></Avatar></ListItemIcon>
                    <ListItemText
                        sx={{ my: 0 }}
                        primary="Estados"
                        primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                        }}
                    />
                </ListItemButton>
                <Divider />
            </FireNav>
            {estados.map((estado, index) => {
                return (
                    <Accordion
                        expanded={props.expanded === estado.codigo_estado}
                        onChange={props.handleChangeEstados(estado.codigo_estado)}
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{ background: 'transparent' }}
                        className={classes.accordionStyle}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownCircleTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <ExploreTwoToneIcon
                                fontSize="large"
                                sx={{
                                    paddingRight: 1,
                                    color: 'rgb(0, 77, 205)',
                                }}
                            />

                            <Chip
                                sx={{ background: 'transparent' }}
                                avatar={<Avatar sx={{ color: 'red !important', }}>{estado.cantidad}</Avatar>}
                                label={
                                    <Typography sx={{ flexShrink: 0, alignSelf: 'center', color: 'azure' }}>
                                        {estado.nombre}
                                    </Typography>}
                            />

                        </AccordionSummary>
                        <AccordionDetails>
                            <ToggleButtonGroup
                                fullWidth
                                color="primary"
                                orientation="vertical"
                                value={optionToggleCiudad}
                                exclusive
                                onChange={handleChangeToggleCiudad}
                                sx={{
                                    paddingBottom: 1,
                                    paddingLeft: 1,
                                }}
                            >
                                {props.ciudades !== undefined ? props.ciudades.map((opcion) => (
                                    <ToggleButton
                                        fullWidth
                                        key={opcion.codigo_ciudad}
                                        value={opcion.nombre}
                                        className={classes.toggleButton}
                                        sx={{
                                            justifyContent: 'left',
                                            paddingLeft: '0px',
                                            paddingRight: '0px'
                                        }}
                                    >
                                        <Chip
                                            sx={{ background: 'transparent', height: 'auto' }}
                                            avatar={
                                                <ExploreTwoToneIcon
                                                    fontSize="medium"
                                                    sx={{
                                                        paddingRight: 1,
                                                        color: 'rgb(0, 77, 205)',
                                                    }} />
                                            }
                                            label={
                                                <Chip
                                                    sx={{ background: 'transparent', height: 'auto', }}
                                                    avatar={<Avatar sx={{ color: 'red !important', }}>{opcion.cantidad}</Avatar>}
                                                    className={classes.chipLabel}
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                flexShrink: 0,
                                                                alignSelf: 'center',
                                                                color: 'azure',
                                                                fontSize: '12px',
                                                                textTransform: 'none',
                                                            }}>
                                                            {opcion.nombre}
                                                        </Typography>}
                                                />
                                            }
                                        />
                                    </ToggleButton>
                                )) : null}
                            </ToggleButtonGroup>
                        </AccordionDetails>
                    </Accordion>)
            })}
        </Grid>
    }

    return (
        <Container component="section" sx={{ mt: 1, mb: 4, background: 'transparent', paddingLeft: 0 }}>
            <Stack
                direction={{ xs: 'column', sm: 'row', }}
            >
                {
                    m320 || m360 || m375 || m425
                        ?
                        <Drawer
                            classes={{ paper: classes.paper }}
                            anchor="left"
                            open={props.drawer}
                            onClose={props.toggleDrawer(false)}
                            onOpen={props.toggleDrawer(true)}
                        >
                            {obtenerFiltro()}
                        </Drawer>
                        :
                        obtenerFiltro()
                }
                <Grid item xs={8}
                    sx={{
                        maxWidth: '100%',
                        flexBasis: '100%',
                        paddingLeft: '0px',
                    }}
                >
                    {
                        props.prestadores.length === 0 ?
                            <WithoutResults tipo_resultado={'paginacion'} descripcion={'No hay ningún elemento para mostrar'} />
                            : <CardResponsive
                                prestadores={props.prestadores}
                                openInsertComment={props.openInsertComment}
                                clickOpenInsertComment={props.clickOpenInsertComment}
                                clickCloseInsertComment={props.clickCloseInsertComment}
                            />
                    }
                </Grid>
            </Stack>
        </Container >
    )
}