import * as React from 'react';

import {
    Container,
    Typography,
    Paper,
    Stack,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import LocationOffTwoToneIcon from '@mui/icons-material/LocationOffTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
}));

const obtenerIcon = (tipo) => {
    switch (tipo) {
        case 'paginacion':
            return <CancelTwoToneIcon sx={{ fontSize: 150, color: '#0072E5' }} />
        case 'mapa':
            return <LocationOffTwoToneIcon sx={{ fontSize: 150, color: '#0072E5' }} />
        case 'promocion':
            return <LocalActivityTwoToneIcon sx={{ fontSize: 150, color: '#0072E5' }} />
    }
}

export default function WithoutResults(props) {

    return (
        <Container
            sx={{
                background: 'transparent',
                height: 280,
                marginLeft: {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    xl: 0,
                    lg: 0,
                }
            }}
        >
            <Paper elevation={5}
                variant="outlined"
                sx={{
                    height: 280,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Stack direction="column" spacing={0}>
                    <Item>
                        {obtenerIcon(props.tipo_resultado)}
                    </Item>
                    <Item>
                        <Typography sx={{ fontSize: { sm: 28, } }} variant="body1" color="text.secondary">
                            {props.descripcion}
                        </Typography>
                    </Item>
                </Stack>

            </Paper>

        </Container>
    );
}