import * as React from 'react';
import {
    Container,
    Button,
} from '@mui/material'

import {
    LayersControl,
    Map,
    Marker,
    Popup,
    TileLayer,
} from 'react-leaflet'

import "leaflet/dist/leaflet.css"

import MarkersLocation from './MarkersLocation';
import ActionButton from './ActionButton';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';

//URL 

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

export default function MapTab(props) {

    const lat = props.lat;
    const lon = props.lon;
    const position = [lat, lon]

    const redirigir = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lon}&zoom=20`);
    }

    function ButtonControl({ position, zoom }) {

        const Btn = (
            <ActionButton
                descripcion="Ir a Google maps"
                background="linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);"
                background_hover="radial-gradient(circle, #5c0067 0%, #00d4ff 100%);"
                visible={'visible'}
                fontsize="13px"
                iconbutton={<MapTwoToneIcon sx={{ mr: 1 }} />}
                onClick={redirigir}
            />
        )

        const positionClass =
            (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
        return (
            <div className={positionClass} >
                <div className="leaflet-control leaflet-bar" style={{ border: '0px transparent' }}>{Btn}</div>
            </div>
        )
    }


    return (
        <Container sx={{ mt: 1, mb: 1, height: '400px', width: '100%' }}>
            <Map
                center={{ lat: props.lat, lng: props.lon }}
                zoom={15}
                style={{ height: '100%', width: '100%' }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <MarkersLocation lat={position[0]} lon={position[1]} nombre={props.nombre} imagen_mapa={props.imagen_mapa} />
                <ButtonControl position="topright" />
            </Map>
        </Container>
    );
}
