import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Alert
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

export default function ServicesTab(props) {

  const useStyles = makeStyles(() => ({
    alertInfo: {
      '&:hover': {
        transform: 'scale(1.0001)',
        boxShadow: `0 1px 3px 0`,
        backgroundColor: '#D1E9F6',
        '& .css-132jxnu-MuiPaper-root': {
          backgroundColor: '#D1E9F6'
        }
      },
    },
  }));

  const classes = useStyles();

  return (
    <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
      {props.servicios.map((servicio, index) => (
        <ListItem sx={{ paddingBottom: 0, paddingTop: '4px' }} key={index}> 
          <Alert
            className={classes.alertInfo}
            iconMapping={{
              info: <CheckCircleTwoToneIcon fontSize="inherit" />,
            }}
            variant="outlined" severity="info" sx={{ width: '100%', padding: { sm: '0px 6px 0px 16px' }, backgroundColor: '#EBEFFF', }} >
            <Typography sx={{ fontSize: { sm: 14, }, fontStyle: 'italic' }} variant="body1" color="text.secondary">
              {servicio.nombre}
            </Typography>

          </Alert>
        </ListItem>
      ))}
    </List>
  );
}
