import * as React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ws_image from '../../assets/images/whatsapp.png'
import ig_image from '../../assets/images/facebook.png'
import fb_image from '../../assets/images/ic-instagram.png'
import page_image from '../../assets/images/page.png'
import email_image from '../../assets/images/email.png'
import { styled } from '@mui/material/styles';
import { IconButton, Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import UploadImagesService from '../../servicio/UploadImagesService';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  objectFit: 'cover',
  display: 'flex',
});

const Input = styled('input')({
  display: 'none',
});

const steps = [
  {
    id: 1,
    label: 'Datos básicos',
    description: `Datos básicos`,
  },
  {
    id: 2,
    label: 'Tipo de prestador de servicio',
    description:
      'Tipo de prestador de servicio',
  },
  {
    id: 3,
    label: 'Comunicaciones',
    description: `Comunicaciones`,
  },
  {
    id: 4,
    label: 'Servicios',
    description: `Servicios`,
  },
  {
    id: 5,
    label: 'Etiquetas',
    description: `Etiquetas`,
  },
];

export function DataBasicProvider(props) {
  const { changeHandler } = props

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        autoFocus
        hiddenLabel
        id="nombre"
        name="nombre"
        label="Nombre"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        value={props.nombre}
        onChange={changeHandler}
      />
      <TextField
        required
        value={props.direccion}
        id="direccion"
        name="direccion"
        label="Direccion"
        type="text"
        fullWidth
        inputProps={{ maxLength: 100 }}
        onChange={changeHandler}
      />
      <TextField
        required
        value={props.ciudad}
        id="ciudad"
        name="ciudad"
        label="Ciudad"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={changeHandler}
      />
    </Box>
  )
}
export function SocialMediaProvider(props) {
  const { changeHandler } = props
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <List>
        <ListItem>
          <IconButton aria-label="ws"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ws_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            height="10px"
            required
            autoFocus
            id="ws"
            name="ws"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ws}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="instagram"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ig_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="fb"
            name="fb"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.fb}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="fb"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={fb_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="ig"
            name="ig"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ig}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="page"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={page_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="www"
            name="www"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.www}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="email"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="email"
              src={email_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="email"
            name="email"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.email}
            onChange={changeHandler}
          />
        </ListItem>

      </List>
    </Box>
  )
}
export function TypeProvider(props) {
  const { changeHandlerChekbox } = props
  return (
    <div>
      <FormGroup>
        <FormControlLabel name="hotel" control={<Checkbox checked={props.tipoprestador.hotel} onChange={changeHandlerChekbox} />} label="Hotel y posada" />
        <FormControlLabel name="restaurant" control={<Checkbox checked={props.tipoprestador.restaurant} onChange={changeHandlerChekbox} />} label="Restaurant" />
        <FormControlLabel name="paseos" control={<Checkbox checked={props.tipoprestador.paseos} />} onChange={changeHandlerChekbox} label="Paseos" />
        <FormControlLabel name="operador" control={<Checkbox checked={props.tipoprestador.operador} />} onChange={changeHandlerChekbox} label="Operador turistico" />
        <FormControlLabel name="buses" control={<Checkbox checked={props.tipoprestador.buses} />} onChange={changeHandlerChekbox} label="Buses y embarcaciones" />
        <FormControlLabel name="toldos" control={<Checkbox checked={props.tipoprestador.toldos} />} onChange={changeHandlerChekbox} label="Toldos" />
        <FormControlLabel name="seguros" control={<Checkbox checked={props.tipoprestador.seguros} />} onChange={changeHandlerChekbox} label="Seguros de viaje" />
      </FormGroup>
    </div>
  )
}
export function ServicesProvider(props) {
  const { changeHandler } = props
  return (
    <Box>
      <List>
        <ListItem>
          <TextField
            required
            autoFocus
            id="servicio1"
            name="servicio1"
            label="Servicio 1"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio1}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <TextField
            required
            id="servicio2"
            name="servicio2"
            label="Servicio 2"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio2}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <TextField
            id="servicio3"
            name="servicio3"
            label="Servicio 3"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio3}
            onChange={changeHandler}
          />
        </ListItem>
      </List>
    </Box>
  );
}
export function ImagesProvider(props) {
  const { changeHandler, uploadFiles, removeImage } = props

  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Box sx={{ paddingBottom: 2 }}>
      <label htmlFor="contained-button-file">
        <Input name="imagenes" accept="image/*" id="contained-button-file" multiple type="file" onChange={changeHandler} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
        {/* <Button variant="contained" component="span">
          Fotos
        </Button> */}
      </label>
      <List dense sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
        {props.vista_previa.length !== 0 ? props.vista_previa.map((imagen, index) => {
          return (
            <ListItem
              key={imagen + index}
              secondaryAction={
                <IconButton sx={{ color: '#006DC3' }} component="span" onClick={() => removeImage(imagen, props.nombres[index])}>
                  <CancelTwoToneIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    key={imagen + index}
                    alt={`Avatar n°${imagen + 1}`}
                    src={imagen}
                    variant="rounded"
                  />
                </ListItemAvatar>
                <ListItemText id={'foto_prestador' + index} primary={props.nombres[index]} />
              </ListItemButton>
            </ListItem>
          );
        }) : <Typography
          sx={{
            paddingLeft: 2,
            fontSize: { sm: 12, },
          }} variant="h6" color="text.secondary">
          {'Vista preliminar'}
        </Typography>}
      </List>      
      {/* <Button variant="contained" component="span" sx={{ marginLeft: 2 }} onClick={uploadFiles}>
        Subir
      </Button> */}
    </Box>
  );
}
export default DataBasicProvider;