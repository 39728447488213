import axios from "axios";

class ProviderService {

    crear = async (codigo_prestador, nombre, direccion, codigo_ciudad, clave, activo, codigo_pais, premium, ws, fb, ig, email, www, servicio1, servicio2, servicio3, etiqueta) => {        
        console.log('HIZO CLICK DOS VECES');
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/", {
            codigo_prestador, nombre, direccion, codigo_ciudad, clave, activo, 
        codigo_pais, premium, ws, fb, ig, email, www, servicio1, servicio2, servicio3, etiqueta
        })
    }

    preregistro = async (data) => {                
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/preregistro", { data })
    }

    obtenerURL_API() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        if (url_principal.toString().includes('localhost:3000')) {
            return process.env.REACT_APP_URL_API_DEV
        } else {
            return process.env.REACT_APP_URL_API
        }
    }

}

export default new ProviderService();