import * as React from 'react';
import { useState, useEffect } from 'react';
import withRoot from './modules/withRoot/withRoot';

import Footer from './modules/components/Footer'
import Header from './modules/components/Header'

import FloatingWhatsApp from 'react-floating-whatsapp-body';
import Data from './servicio/Data';

import { DivUI, H1, HeaderUI } from './modules/components/BaseComponents'

import ChatBotReact from './modules/components/ChatBotReact';

import { DivUIPrincipal } from './modules/components/BaseComponents'

import AppBar from '@mui/material/AppBar';

import LayoutCenter from './modules/components/LayoutCenter'

import Backdrop from '@mui/material/Backdrop';
import { Box, Container } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

import { Alert as MuiAlert, Snackbar } from '@mui/material'

import InsertComments from './modules/components/InsertComment';
import CommentsService from './servicio/CommentsService';

import { HashRouter, Route, Routes, Switch } from 'react-router-dom';

import SkeletonCard from "./modules/components/SkeletonCard"

import HomeLayout from './HomeLayout';

import StepperProvider from './modules/components/StepperProvider'

import Home from './modules/pages/Home'

const variants = ['h1', 'h3', 'body1', 'caption'];


function App() {

  return (
    <React.Fragment>
      <Header />
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={openLoading}
      >
        <FacebookCircularProgress />
      </Backdrop> */}
      <Routes>
        <Route path="/" element={<HomeLayout />} />
        <Route path="/home" element={<Home />} />
        <Route path="/preregistro" element={<StepperProvider />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(App);
