import axios from "axios";

class UploadImagesService {

    subirImagenes = async (files, id, ubicacion) => {
        //console.log(files);
        if (files.length === 0 || files === undefined) {
            return "Debe seleccionar un archivo."
        }

        const config = {
            headers: { "content-type": `${files[0].type}` }
        };

        const formData = new FormData();
        //data.append('image', files[0]);

        // for (let i = 0; i < files.length; i++) {            
        //     data.append(`images`, files[i])
        // }

        if (files.length === 1) {

            formData.append('image', files[0])
            
            return axios.post(`${this.obtenerURL_API()}` + `/api/prestadores_imagenes/simple/${id}/${ubicacion}`, formData, config)

        } else {

            for (let i = 0; i < files.length; i++) {
                formData.append('images', files[i])
            }

            return axios.post(`${this.obtenerURL_API()}` + `/api/prestadores_imagenes/multiple/${id}/${ubicacion}`, formData, config)

        }

    }

    preregistro = async (data) => {
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/preregistro", { data })
    }

    obtenerURL_API() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        if (url_principal.toString().includes('localhost:3000')) {
            return process.env.REACT_APP_URL_API_DEV
        } else {
            return process.env.REACT_APP_URL_API
        }
    }

}

export default new UploadImagesService();