import * as React from 'react';
import Container from '@mui/material/Container';
import Card from '../components/CardResponsive'
import Tabs from '../components/Tabs'
import AdvancedSearch from '../components/AdvancedSearch';

import useMediaQuery from '@mui/material/useMediaQuery';

import {
    Grid,
    Stack,
    List,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';

const FireNav = styled(List)({
    background: 'linear-gradient(to left bottom, rgb(0, 77, 205) 20%, rgba(0, 77, 205, 0.584))',
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 6,
        paddingBottom: 6,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '&:hover': {
        '& .css-le9ab0-MuiAvatar-root': {
            background: 'azure'
        },
    }
});

export default function LayoutCenter(props) {

    const matches = useMediaQuery('(max-width:600px)');

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)');
    const m2560 = useMediaQuery('(max-width:2560px)');

    const visibility = () => {
        if (m320) {
            return 'hidden'
        } else if (m375) {
            return 'hidden'
        } else if (m425) {
            return 'hidden'
        } else {
            return 'visible'
        }
    }

    const height = () => {
        if (m320) {
            return 0
        } else if (m375) {
            return 0
        } else if (m425) {
            return 0
        }
    }

    const maxWidth = () => {
        if (m320) {
            return '100%'
        } else if (m360) {
            return '100%'
        } else if (m375) {
            return '100%'
        } else if (m411) {
            return '100%'
        } else if (m425) {
            return '100%'
        } else if (m1600) {
            return '80%'
        } else if (m1800) {
            return '80%'
        } else {
            return '80%'
        }
    }

    return (
        <Container sx={{ marginTop: 20, paddingRight: 0 }}>
            <Stack
                direction={{ xs: 'column', sm: 'row', }}
            >
                <Grid item xs={4}
                    sx={{
                        maxWidth: {
                            xs: '100% !important',
                            sm: '23.333333% !important',
                            md: '21.333333% !important',
                            xl: '23.333333% !important',
                            lg: '100% !important',
                        },
                        minWidth: '24.333333%',                        
                    }}
                >
                    <Container sx={{ visibility: visibility(), height: height() }}>
                        <FireNav component="nav" disablePadding style={{ boxShadow: `0 3px 6px 0 `, }}>
                            <Divider />
                            <ListItemButton component="a">
                                <ListItemIcon><Avatar><FilterAltTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} /></Avatar></ListItemIcon>
                                <ListItemText
                                    sx={{ my: 0 }}
                                    primary="Filtro avanzado"
                                    primaryTypographyProps={{
                                        fontSize: 18,
                                        fontWeight: 'medium',
                                        letterSpacing: 1,
                                        lineHeight: 1.3,
                                        color: 'azure'
                                    }}
                                />
                            </ListItemButton>
                            <Divider />
                            <Divider />
                        </FireNav>
                    </Container>
                </Grid>
                <Grid item xs={8}
                    sx={{
                        maxWidth: maxWidth(),
                        flexBasis: {
                            lg: '78.666667%'
                        },
                        paddingRight: {
                            xs: '16px',
                            sm: '16px',
                            md: '16px',
                            xl: '0px',
                            lg: '0px',
                        },
                    }}
                >
                    <Tabs
                        handleChangeTab={props.handleChangeTab}
                        codigo_servicio={props.codigo_servicio}
                        contador_todos={props.contador_todos}
                        contador_hoteles={props.contador_hoteles}
                        contador_restaurantes={props.contador_restaurantes}
                        contador_paseos={props.contador_paseos}
                        contador_toldos={props.contador_toldos}
                        contador_embarcaciones={props.contador_embarcaciones}
                        contador_operadores={props.contador_operadores}
                        contador_seguros={props.contador_seguros}
                        contador_destacados={props.contador_destacados}                        
                    />                    
                </Grid>
            </Stack>
            <Stack>
                <Grid id="grid_id" item xs={4}
                    sx={{
                        maxWidth: {
                            xs: '100% !important',
                            sm: '23.333333% !important',
                            md: '21.333333% !important',
                            xl: '100% !important',
                            lg: '100% !important',
                        },
                    }}
                >
                    <AdvancedSearch
                        prestadores={props.prestadores}
                        codigo_servicio={props.codigo_servicio}
                        handleTogglePaises={props.handleTogglePaises}
                        handleChangeEstados={props.handleChangeEstados}
                        handleToggleCiudades={props.handleToggleCiudades}
                        checkedCiudades={props.checkedCiudades}
                        busqueda={props.busqueda}
                        checkedPaises={props.checkedPaises}
                        ciudades={props.ciudades}
                        expanded={props.expanded}
                        openInsertComment={props.openInsertComment}
                        clickOpenInsertComment={props.clickOpenInsertComment}
                        clickCloseInsertComment={props.clickCloseInsertComment}
                        drawer={props.drawer}
                        toggleDrawer={props.toggleDrawer}
                    />
                </Grid>
            </Stack>



            {/* <Container sx={{ width: '70%', display: 'inline-block' }}>
                <Card prestadores={prestadores} />
            </Container> */}
        </Container>
    );
}