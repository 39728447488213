import * as React from 'react';

import {
    Box,
    Container,
    Typography,
    Paper,
    Stack,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ecosistemas from '../../assets/images/ecosistemas.png'

import ContainerEcoOptions from '../components/ContainerEcoOptions';

const Img = styled('img')({
    margin: 0,
    width: '100%',
    minHeight: 'calc(100vh - 0px)',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    opacity: 0.5,
});

const opciones = [
    {
        id: 'posadas',
        title: 'posadas',
    },
    {
        id: 'restaurant',
        title: 'Restaurant',
    },
    {
        id: 'lanchas',
        title: 'Lanchas',
    },
    {
        id: 'toldos',
        title: 'Toldos',
    },
    {
        id: 'paseos',
        title: 'Paseos',
    },
];

export default function Home(props) {

    const useStyles = makeStyles(() => ({
        container: {
            maxWidth: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    }));

    const classes = useStyles();

    return (
        <Container
            className={classes.container}
            sx={{
                background: 'transparent',
                alignContent: 'center',                
            }}
        >
            <Box
                sx={{
                    margin: 0,
                    width: '100vw',
                    minHeight: 'calc(100vh - 0px)',
                    position: 'relative',
                }}
            >
                <Img src={ecosistemas} />
            </Box>

            <Container>
                <ContainerEcoOptions opciones={opciones} />
            </Container>

        </Container >
    );
}